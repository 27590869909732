import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
const showCluster = process.env.REACT_APP_IS_SINGLE_CLUSTER;

export default class Merchant_Shops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data
    };
  }  
  render() {
    let condition;
    if (showCluster == "true") {
      condition = this.state.data.product_category !== "PAYEATFWD" && this.state.data.product_category !== "KIOSK" && this.state.data.product_category !== "Test Items"
    } else {
      condition = this.state.data.product_category !== "PAYEATFWD" && this.state.data.product_category !== "KIOSK" && this.state.data.product_category !== "Test Items"
    }
    if (window.innerWidth < 481) {
      return (
        <>
          {condition ?
            <Card sx={{ maxWidth: 200 }} style={{ width: "100%", borderRadius: '10px' }}>
              {this.state.data.product_category_img_url !== undefined && this.state.data.product_category_img_url !== null ? 
              <CardMedia
              component="img"
              height="80"
              image={this.state.data.product_category_img_url}
              alt="category image"
            /> : <CardMedia
            component="img"
            height="80"
            image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
            alt="category image"
          />}
          </Card>:<></>}  
        </>
      );
    } else {
      return (
        <>
          {condition ?
            <Card sx={{ maxWidth: 345 }} style={{ width: "100%", borderRadius: '10px' }}>
              {this.state.data.product_category_img_url !== undefined && this.state.data.product_category_img_url !== null ? 
              <CardMedia
              component="img"
              height="120"
              image={this.state.data.product_category_img_url}
              alt="category image"
            /> : <CardMedia
            component="img"
            height="120"
            image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
            alt="category image"
          />}
          </Card>:<></>}  
        </>
      );
   }
  }
}