import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Order from '../components/order_details_component'
import "../../../styles/style.css"
import axios from "axios";
import commonConfigs  from "../../../config";
import ErrorAlert from "../../common/components/alert_error_component";

import {
    Link,
  } from "react-router-dom";
import { CheckCircle, ThirtyFpsSelect } from "@mui/icons-material";
import emptycart from "../../../assets/background/emptycart.gif"

const isCluster = process.env.REACT_APP_IS_CLUSTER;
class Checkout extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      merchantid: localStorage.getItem("merchantid"),
      merchantname: localStorage.getItem("merchantname"),
      merchantunit: localStorage.getItem("merchantunit"),
      orders: JSON.parse(localStorage.getItem("orders")),
      ordersdisplay: JSON.parse(localStorage.getItem("ordersdisplay")),
      dinemode: localStorage.getItem('dinemode'),
      peftype: localStorage.getItem('peftype'),
      anonymizedid: localStorage.getItem('anonid'),
      vouchercode: "",
      voucheramt: "",
      vouchertype: "",
      voucherstatus: "",
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
      isButtonDisabled: false,
      collection_datetime: localStorage.getItem('collection_datetime') ?? null,
      projectname: localStorage.getItem('projectname'),
      orgid: localStorage.getItem("orgid"),
      errorMsg: "",
      functionIndex: "",
      totalorderval: 0,
      isVoucherApplied: false,
      platformSyscode: localStorage.getItem("platformSyscode"),
    }
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;
    // ////console.log(secondstime);
   
    
    // Check if we're at zero.
    if (secondstime == 0) {
      clearInterval(this.timer);
      // alert("Session Timeout");
      this.setState({
        timeout: true,
      });
    } else {
      this.setState({
        seconds: secondstime,
      });
    }
  }
  // before checkout (if there is voucher code present)
  // 1. send the voucher code to check voucher api from checkout function
  // 2. if voucher status and api response is not null, check if total order is lesser than voucher amount (dont allow), else if set voucher status to notvalid
  // 3. if voucher type 2 can proceed to checkout (add voucher code, voucher amt, voucher type to checkout request object)
  // 4. if voucher type 4, proceed to check with items in cart
  // 5. first split the voucher code from the response
  // 6. then for each product in the cart, compare the product voucher code with the splitted voucher code from response
  // 7. if the check is true, set the voucher code, voucher type, voucher amt to the state to represent the current voucher this will be sent to checkout
  // 8. else sent voucher status invalid and provide error message
  async checkVoucher() {
    try {
      // alert("running check voucher")
      var voucherCode = this.state.vouchercode
      console.log(voucherCode)
      var takeawaycharge = parseFloat(localStorage.getItem("takeawaycharge"));
      var additional = takeawaycharge * JSON.parse(localStorage.getItem("orders")).length;
      var orders = JSON.parse(localStorage.getItem("ordersdisplay"))
      console.log(orders)
      var price = 0;
      var discountedprice = 0;
      var qty = 0;
      for (var i = 0; i < orders.length; i++) {
        qty += parseInt(orders[i].quantity);
        // //console.log(qty);
        if (orders[i].add_ons.length > 0) {
          for (var x = 0; x < orders[i].add_ons.length; x++) {
            price += parseFloat(orders[i].add_ons[x].product_price) * parseInt(orders[i].add_ons[x].quantity) * parseInt(orders[i].quantity);
          }
        
        }
        // price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);
        if (parseInt(orders[i].product_discount_percent) > 0 && parseFloat(orders[i].product_discount_value) > 0) {
          discountedprice = (parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent)) / 100)) * parseInt(orders[i].quantity)
          if ((parseFloat(orders[i].product_price) - discountedprice) > 0) {
            price += discountedprice
          } else {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);

          }
        } else if (parseInt(orders[i].product_discount_percent) > 0 && (parseFloat(orders[i].product_discount_value) == 0 || parseFloat(orders[i].product_discount_value) == null)) {
          discountedprice = (parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent)) / 100)) * parseInt(orders[i].quantity)
          if ((parseFloat(orders[i].product_price) - discountedprice) > 0) {
            price += discountedprice
          } else {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);

          }
          // price += (parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent))/100))*parseInt(orders[i].quantity)
        } else if (parseFloat(orders[i].product_discount_value) > 0 && (parseFloat(orders[i].product_discount_percent) == 0 || parseFloat(orders[i].product_discount_percent) == null)) {
          discountedprice = (parseFloat(orders[i].product_price) - parseFloat(orders[i].product_discount_value)) * parseInt(orders[i].quantity)
          if (discountedprice <= 0) {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);
          } else {
            price += discountedprice
          }
        } else {
          price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);
        }
      }
      if (navigator.onLine && voucherCode.includes("_")) {
        // GetVouchers: (projectname, orgid, vouchercode,orderval) 
        console.log(this.state.projectname);
        console.log(this.state.orgid);
        console.log(voucherCode);
        console.log(price);
        console.log(commonConfigs.accountId);
        console.log(commonConfigs.apiUrls.GetVouchers(this.state.projectname, this.state.orgid, voucherCode, price, commonConfigs.accountId));
        
        await axios
          .get(commonConfigs.apiUrls.GetVouchers(this.state.projectname, this.state.orgid, voucherCode, price, commonConfigs.accountId), {
            headers: commonConfigs.apiHeader,
          })
          .then((res) => {
            console.log(res.data.result.data);

            if (res.data.status == "success") {
              var response = res.data.result.data;
              console.log(response);
              // var res = JSON.parse(this.responseText);
              // response = res.result.data;
              if (response.voucher_value != null && response.voucher_value !== undefined ) {
                console.log("condition met")
                
              
                // this.setState({
                //   totalorderval: price
                // })
                if (price < response.voucher_value) {
                  alert('Total redemption value exceeds payable amount.')
                  this.setState({
                    voucherstatus: 'isNotValid',
                    vouchercode: '',
                  })
                } else {
                  if (response.voucher_type === 4) {
                    var splitVoucher = voucherCode.split("_")
                    var checkVoucherMatches = false;
                    for (const cartItem of orders) {
                      console.log(cartItem)
                      if (cartItem.product_voucher_code !== "" && cartItem.product_voucher_code.includes(splitVoucher[0])) {
                        checkVoucherMatches = true;
                        break;
                      }
                    }
                    if (checkVoucherMatches) {
                      this.setState({
                        voucherstatus: 'isValid',
                        voucheramt: response.voucher_value,
                        vouchertype: response.voucher_type,
                        isVoucherApplied: true,
                      })
                      if (this.state.dinemode == 4) {
                        // initialPrice.textContent += " - $" + response.voucher_value + " = $" + (price - response.voucher_value).toFixed(2);
                        document.getElementById("dineinTotal").textContent = "$" + (price - response.voucher_value).toFixed(2);
                      } else if (this.state.dinemode == 3) {
                        // initialPrice.textContent += " - $" + response.voucher_value + " = $" + (price + additional - response.voucher_value).toFixed(2);
                        document.getElementById("takeawayTotal").textContent = "$" + (price + additional - response.voucher_value).toFixed(2);
                      }
                    } else {
                      alert("Applied voucher is not applicable for product")
                      this.setState({
                        vouchercode: '',
                        voucherstatus: 'isNotValid',
                      })
                    }
                  } else if (response.voucher_type === 2) {
                    // for type 2
                    this.setState({
                      voucherstatus: 'isValid',
                      voucheramt: response.voucher_value,
                      vouchertype: response.voucher_type,
                      isVoucherApplied: true,
                    })
                    if (this.state.dinemode == 4) {
                      document.getElementById("dineinTotal").textContent = "$" + (price - response.voucher_value).toFixed(2);
                    } else if (this.state.dinemode == 3) {
                      document.getElementById("takeawayTotal").textContent = "$" + (price + additional - response.voucher_value).toFixed(2);
                    }
                  } else {
                    // kiosk unable to use voucher type 1 and 3
                    this.setState({
                      vouchercode: '',
                      voucherstatus: 'invalidType',
                    })
                  }
                }
              } else {
                // console.log('condition failed')
                this.setState({
                  vouchercode: '',
                  voucherstatus: 'isNotValid',
                })
                // console.log(res.data.result.message.message)
                alert(res.data.result.data.message);
              }
            } else {
              console.log("Error Message: " + res)
              alert("Error during voucher checks!");
              this.setState({ isButtonDisabled: false })
            }
          })
          .catch((err) => {
            alert("Error Code:" + err)
            console.log(err);
            this.setState({ isButtonDisabled: false })

          });
      } else {
        this.setState({
          errorMsg: "Oops! Network is slow. Please try again later.",
          // load:false
        });
      }
      return "Check Voucher run finished";
    } catch (error) {
      console.log(error)
      // alert(e);
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "01"
      });
    }
  }
  async checkout() {
    try {
      this.startTimer();
      this.setState({ isButtonDisabled: true });
      // var paymentId = 1;
      // if (this.state.peftype == "Claim") {
      //   paymentId = 'claim_mfg_' + Date.now();
      // }
      // console.log(JSON.parse(localStorage.getItem('orders')));
      // var orders = JSON.parse(localStorage.getItem('orders'));

      // var totaldiscountamt = 0;

      // console.log(orders);
      // for (var i = 0; i < orders.length; i++) {
      //   totaldiscountamt += parseFloat(orders[i].discount_amt);
      // }
      // console.log(this.state.collection_datetime);
      // if (parseInt(this.state.dinemode) == 4) {
      //   var body = {
      //     merchant_id: this.state.merchantid,
      //     org_id: this.state.orgid,
      //     account_id: "",
      //     payment_id: paymentId, 
      //     order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
      //     order_platform_sys_code: 5, // 3 - mobile, 2 - kiosk
      //     order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
      //     discount_amt:totaldiscountamt,
      //     discount_percent:0,
      //     discount_type:totaldiscountamt > 0 ? 1 : 0,
      //     txn_order_consumer_details: orders,
      //     voucher_code: this.state.promocode,
      //     anonymized_id: this.state.anonymizedid,
      //     // collection_datetime: this.state.collection_datetime,
      //     // org_programme_id: 5,
      //     // discount_type:7,
      //   }
      // } else {
      //   var body = {
      //     merchant_id: this.state.merchantid,
      //     org_id: this.state.orgid,
      //     account_id: "",
      //     payment_id: paymentId, 
      //     order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
      //     order_platform_sys_code: 5, // 3 - mobile, 2 - kiosk
      //     order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
      //     discount_amt:totaldiscountamt,
      //     discount_percent:0,
      //     discount_type:totaldiscountamt > 0 ? 1 : 0,
      //     txn_order_consumer_details: orders,
      //     voucher_code: this.state.promocode,
      //     anonymized_id: this.state.anonymizedid,
      //     collection_datetime: this.state.collection_datetime,
      //     // org_programme_id: 5,
      //     // discount_type:7,
      //   }
      // }
      var paymentId = 1;
      var discountType = 0;
      
      console.log(JSON.parse(localStorage.getItem('orders')));
      var orders = JSON.parse(localStorage.getItem('orders'));

      var totaldiscountamt = 0;

      console.log(orders);
      for (var i = 0; i < orders.length; i++) { 
        totaldiscountamt += parseFloat(orders[i].discount_amt);            
      }
      if (this.state.peftype == "Claim") {
        paymentId = 'claim_mfg_' + Date.now();
        discountType = this.state.discounttype
      } else {
        discountType = totaldiscountamt > 0 ? 1 : 0
      }
      if (this.state.vouchercode !== '') {
        console.log(this.state.voucherstatus);
        if (this.state.voucherstatus === 'isValid') {
          console.log(this.state.dinemode);
          if (parseInt(this.state.dinemode) == 4) {
            var body = {
              merchant_id: this.state.merchantid,
              org_id: this.state.orgid,
              account_id: commonConfigs.accountId,
              payment_id: paymentId,
              order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
              platform_syscode: parseInt(this.state.platformSyscode),
              order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
              discount_amt: totaldiscountamt,
              discount_percent: 0,
              discount_type: totaldiscountamt > 0 ? 1 : 0,
              txn_order_consumer_details: orders,
              voucher_code: this.state.promocode,
              anonymized_id: this.state.anonymizedid,
              voucher_code: this.state.vouchercode,
              voucher_amt: this.state.voucheramt,
              voucher_type: this.state.vouchertype,
              // collection_datetime: this.state.collection_datetime,
              // org_programme_id: 5,
              // discount_type:7,
            }
          } else {
            var body = {
              merchant_id: this.state.merchantid,
              org_id: this.state.orgid,
              account_id: commonConfigs.accountId,
              payment_id: paymentId,
              order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
              platform_syscode: parseInt(this.state.platformSyscode),
              order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
              discount_amt: totaldiscountamt,
              discount_percent: 0,
              discount_type: totaldiscountamt > 0 ? 1 : 0,
              txn_order_consumer_details: orders,
              voucher_code: this.state.promocode,
              anonymized_id: this.state.anonymizedid,
              collection_datetime: this.state.collection_datetime,
              voucher_code: this.state.vouchercode,
              voucher_amt: this.state.voucheramt,
              voucher_type: this.state.vouchertype,
              // org_programme_id: 5,
              // discount_type:7,
            }
          }
          // var body = {
          //   merchant_id: this.state.merchantid,
          //   org_id: this.state.orgid,
          //   // 96f4f3da-250c-43a1-bab2-31fddf5605a6
          //   account_id: commonConfigs.accountId,
          //   payment_id: paymentId,
          //   order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
          //   order_platform_sys_code: 5, // 3 - mobile, 2 - kiosk
          //   order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
          //   discount_amt: totaldiscountamt,
          //   discount_percent: 0,
          //   discount_type: discountType,
          //   txn_order_consumer_details: JSON.parse(localStorage.getItem('orders')),
          //   voucher_code: this.state.vouchercode,
          //   voucher_amt: this.state.voucheramt,
          //   voucher_type: this.state.vouchertype,
          //   anonymized_id: this.state.anonymizedid,
          //   org_programme_id: 5,
          //   // discount_type:7,
          // }
          console.log(body);
        } else if (this.state.voucherstatus === 'invalidType') {
          alert("This voucher cannot be used for Ecommerce transactions!");
          this.setState({ isButtonDisabled: false });
        } else {
          alert("Voucher is invalid");
          this.setState({ isButtonDisabled: false });
        }
      } else {
        if (parseInt(this.state.dinemode) == 4) {
          var body = {
            merchant_id: this.state.merchantid,
            org_id: this.state.orgid,
            account_id: commonConfigs.accountId,
            payment_id: paymentId,
            order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
            platform_syscode: parseInt(this.state.platformSyscode), 
            order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
            discount_amt: totaldiscountamt,
            discount_percent: 0,
            discount_type: totaldiscountamt > 0 ? 1 : 0,
            txn_order_consumer_details: orders,
            voucher_code: this.state.promocode,
            anonymized_id: this.state.anonymizedid,
            // collection_datetime: this.state.collection_datetime,
            // org_programme_id: 5,
            // discount_type:7,
          }
        } else {
          var body = {
            merchant_id: this.state.merchantid,
            org_id: this.state.orgid,
            account_id: commonConfigs.accountId,
            payment_id: paymentId,
            order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
            platform_syscode: parseInt(this.state.platformSyscode), 
            order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
            discount_amt: totaldiscountamt,
            discount_percent: 0,
            discount_type: totaldiscountamt > 0 ? 1 : 0,
            txn_order_consumer_details: orders,
            voucher_code: this.state.promocode,
            anonymized_id: this.state.anonymizedid,
            collection_datetime: this.state.collection_datetime,
            // org_programme_id: 5,
            // discount_type:7,
          }
        }
      }
        // var body = {
        //   merchant_id: this.state.merchantid,
        //   org_id: this.state.orgid,
        //   // 96f4f3da-250c-43a1-bab2-31fddf5605a6
        //   account_id: commonConfigs.accountId,
        //   payment_id: paymentId,
        //   order_type: parseInt(this.state.dinemode), // 0-delivery 2-takeaway 4-dinein 99-payeatfwd
        //   order_platform_sys_code: 2, // 3 - mobile, 2 - kiosk
        //   order_payment_type: 1, // 0-cash 1-netsqr 2-netsclick 3-sgqr,
        //   discount_amt: totaldiscountamt,
        //   discount_percent: 0,
        //   discount_type: discountType,
        //   txn_order_consumer_details: JSON.parse(localStorage.getItem('orders')),
        //   anonymized_id: this.state.anonymizedid,
        //   org_programme_id: 5,
        //   // discount_type:7,
        // }
        console.log(body); 

        const method = "POST";
        const createOrderUrl = commonConfigs.apiUrls.CreateOrder(this.state.projectname);
        const requestBody = body; 
  
        // Generate authentication headers
        const authHeaders = commonConfigs.generateHeaders(method, createOrderUrl, requestBody);
        
        await axios
          .post(createOrderUrl, body, {
            headers: authHeaders,
          })
          .then((res) => {
            if (res.data.status == "success") {
              var response = res.data.result.data;
              console.log(response);

              // if (response.status_code !== 500 ) {
              if (response.status_code !== 500) {

                localStorage.setItem("orderid", response.consumer_order_id);
                localStorage.setItem("orderno", response.order_no);
                localStorage.setItem("amt", response.order_total_nett_amt);
                localStorage.setItem("orderdatetime", response.order_datetime);
                var hangoutButton = document.getElementById("checkout");
                hangoutButton.click();
              } else {
                alert("Error: " + response.message);
                this.setState({ isButtonDisabled: false })
              }
            } else {
              alert("Error Message: " + response.message);
              this.setState({ isButtonDisabled: false })
            }
          })
          .catch((err) => {
            alert("Error Code:" + err)
            console.log(err);
            this.setState({ isButtonDisabled: false })

          });
      
    }catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "02"
      })
    }     
  }
  handleVoucherCode = (e) => {
    console.log(e.target.value)
    this.setState({
      vouchercode: e.target.value
    })
  }
  updateParentValue = (newValue) => {
    this.setState({ ordersdisplay: JSON.parse(newValue) });
  };
  removeVoucher() {
    try{
      this.setState({
        vouchercode: "",
        voucherstatus: "",
        voucheramt: "",
        vouchertype: "",
        isVoucherApplied: false,
      })
      if(this.state.dinemode == 4) {
        document.getElementById("dineinTotal").textContent = "";
      } else if (this.state.dinemode == 3) {
        document.getElementById("takeawayTotal").textContent = "";
      }
    }catch (e) {
      this.setState({
        errorMsg: "Error removing voucher",
        functionIndex: "05"
      })
    }
  }
  render() {
    clearInterval();
    this.startTimer();
    let qty = 0;
    let price = 0;
    let discountedprice = 0;
    let takeawaycharge;
    let additional = 0;
    let bgstyle;
    let ispef;
    let linkurl;
    let btndisplay;
    let btnvoucherdisplay;
    let subtotal = 0;
    const { isButtonDisabled } = this.state;
    const {isVoucherApplied} = this.state;
    takeawaycharge = parseFloat(localStorage.getItem("takeawaycharge"));
    const disabledStyle = {
      backgroundColor: 'grey',
      color: 'white',
      cursor: 'not-allowed',
    };
    const removeVoucherStyle = {
      backgroundColor: 'red',
      color: 'white'
    }
    if(this.state.orders == null){
      qty = 0;
      bgstyle = {backgroundColor:'white',borderRadius:'20px', margin:'20px'};

    }else{
      bgstyle = {};
      //additional = takeawaycharge*this.state.orders.length;
      var orders = this.state.ordersdisplay;
      for(const cartItem of orders) {
        additional += takeawaycharge*parseInt(cartItem.quantity)
      }
      for(var i = 0;i<orders.length;i++){
        qty += parseInt(orders[i].quantity);
        if (orders[i].add_ons.length > 0) {
          for (var x = 0;x<orders[i].add_ons.length;x++){
            price += parseFloat(orders[i].add_ons[x].product_price) * parseInt(orders[i].add_ons[x].quantity) * parseInt(orders[i].quantity);
            if(x == 0 && x == orders[i].add_ons.length) {
              subtotal = price;
            } else {
              subtotal += parseFloat(orders[i].add_ons[x].product_price)*parseInt(orders[i].add_ons[x].quantity)*parseInt(orders[i].quantity);
            }
          }
         
        }
        if (parseInt(orders[i].product_discount_percent) > 0 && parseFloat(orders[i].product_discount_value) > 0) {
          discountedprice = (parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent)) / 100)) * parseInt(orders[i].quantity)
          if ((parseFloat(orders[i].product_price) - discountedprice) > 0) {
            price += discountedprice            
            subtotal += parseFloat(orders[i].product_price)*parseInt(orders[i].quantity); 
          } else {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);    
            subtotal += price;   

          }
        } else if (parseInt(orders[i].product_discount_percent) > 0 && (parseFloat(orders[i].product_discount_value) == 0 || parseFloat(orders[i].product_discount_value) == null)) {
          discountedprice = (parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent))/100))*parseInt(orders[i].quantity)
          if ((parseFloat(orders[i].product_price) - discountedprice) > 0) {
            price += discountedprice  
            subtotal += parseFloat(orders[i].product_price)*parseInt(orders[i].quantity);  
          } else {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);
            subtotal += price;

          }
        } else if (parseFloat(orders[i].product_discount_value) > 0 && (parseFloat(orders[i].product_discount_percent) == 0 || parseFloat(orders[i].product_discount_percent) == null)) {
          discountedprice = (parseFloat(orders[i].product_price) - parseFloat(orders[i].product_discount_value)) * parseInt(orders[i].quantity)
          if (discountedprice <= 0) {
            price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);  
            subtotal += price;   
          } else {
            price += discountedprice                    
            subtotal += parseFloat(orders[i].product_price)*parseInt(orders[i].quantity);
          }
        } else {
          price += parseFloat(orders[i].product_price) * parseInt(orders[i].quantity);     
          if(i == 0 && i == orders.length) {
            subtotal = parseFloat(orders[i].product_price)*parseInt(orders[i].quantity);  
          } else {
            subtotal += parseFloat(orders[i].product_price)*parseInt(orders[i].quantity);
          }  
        }
      }
    }

    if(this.state.dinemode == 99){
      ispef = localStorage.getItem('peftype');
      if(ispef == 'Donate'){
        btndisplay = "Donate";
        linkurl = '/payments'
      }else{
        btndisplay = "Claim";
        // linkurl = '/checkout/scannric'
        linkurl = '/success'
      }
    }else{
        btndisplay = "Pay";
        linkurl = '/checkout/contact'
        // linkurl = '/checkout/chas'
    }
    if(this.state.isVoucherApplied != true) {
      btnvoucherdisplay = "Apply Voucher"
    } else {
      btnvoucherdisplay = "Remove Applied Voucher"
    }
    if (window.innerWidth < 481) {
      return (
        <div>
             
        <div className="drawerWeb" style={{ height: 'auto', padding:'10px'}}>
          <Link to={{pathname:`/products`}}>
            <button className="backMobile" >Back</button>
          </Link>
        </div>
      <div style={bgstyle}>
          <h2 className="center" style={{fontSize:'22px', marginTop:0}}>CHECK OUT</h2>
          {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <><h4 className="stall" style={{fontSize:'18px'}}>{this.state.merchantname}: {this.state.merchantunit}</h4></> : <></>}
            {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <><Order onUpdate={this.updateParentValue} data={this.state.orders} displaydata={this.state.ordersdisplay}/></>:
          <div style={{textAlign:'center',marginTop:'5%'}}>
            <img src={emptycart} alt="Empty Cart" width={250} style={{borderRadius:'10px'}}/>
            <p className="text" style={{fontSize:'20px', color:'#6D7278'}}>Oops...Your Cart is Empty.</p>
                { isCluster == 2 ? <Link to='/web/clusters' style={{textDecoration: 'none'}}>
              <button className="button5Web" style={{width:'50%',marginTop:'30px', fontSize:'12px', height:'30px'}}>BROWSE STALLS</button>
                </Link> : 
            <Link to='/merchants' style={{textDecoration: 'none'}}>
            <button className="button5Web" style={{width:'50%',marginTop:'30px', fontSize:'12px', height:'30px'}}>BROWSE STALLS</button>
          </Link>}
            
          </div>}
          
      </div>
      {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <div className="checkoutCartWeb" style={{position:'absolute'}}>  
          <div className="paymentCartWeb" style={{padding:'10px'}}>
            <div>
            {this.state.dinemode == 3 ? <>
              <div className="voucherContainerMobile">
                    <div style={{display:'inline-block', width:'100%', marginBottom:'10px'}}>
                    <label className="text" htmlFor="vouchercode" style={{ fontWeight: 'bold', fontSize: '12px', verticalAlign: 'middle' }}>Voucher Code: </label>
                    {isVoucherApplied ? <><p name="vouchercode" id="vouchercode" className="vouchercodeMobile" style={{display: 'inline-block'}}>{this.state.vouchercode}</p></>:<><input name="vouchercode" placeholder="Enter a Voucher Code" id="vouchercode" className="vouchercodeMobile" onChange={(e)=>this.handleVoucherCode(e)}/></> }
                      
                    </div>
                    <div style={{display:'inline-block', width:'100%', textAlign:'end'}}>
                      <button
                      className="checkVoucherMobile"
                      style={isVoucherApplied ? removeVoucherStyle : null}
                      onClick={isVoucherApplied ? () => this.removeVoucher() : () => this.checkVoucher()}
                        >
                      {btnvoucherdisplay}
                      </button>
                    </div>
                  </div>
                  {isVoucherApplied ?
                    <div className="text" style={{ fontSize: '12px', display: 'inline-block', width: '50%', margin: 0 }}>
                      <h3 style={{margin:0, width:'fit-content'}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="takeawayTotal" style={{color:'#1F5626'}}></span></h3>
                      <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500', width:'fit-content'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                      <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500', width:'fit-content'}}>eVoucher: - ${parseInt(this.state.voucheramt).toFixed(2)}</p>
                      <p className="text" style={{ fontSize: '12px', margin: 0, color: '#6D7278', fontWeight: '500', width: 'fit-content' }}>Takeaway Charge: + ${additional.toFixed(2)}</p>
                    </div>
                    : <div className="text" style={{ fontSize: '12px', display: 'inline-block', width: '50%', margin: 0 }}>
                         <h3 style={{margin:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="takeawayTotal" style={{color:'#1F5626'}}>${(price + additional).toFixed(2)}</span></h3>
                        <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                        <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500'}}>Takeaway Charge: + ${additional.toFixed(2)}</p>
                    </div>
                  }
              <Link to='/checkout/contact' style={{display:'inline-block', width:'50%',verticalAlign:'bottom'}}>
              <button
                    className="buttonCheckoutMobile"
                    style={isButtonDisabled ? disabledStyle : null}
                    disabled={isButtonDisabled}
                    onClick={()=>this.checkout()}
                    >
                    {btndisplay}
                  </button>
              </Link>
            </>:<>
              <div className="voucherContainerMobile">
                <div style={{display:'inline-block', width:'100%', marginBottom:'10px'}}>
                  <label className="text" htmlFor="vouchercode" style={{fontWeight:'bold', fontSize:'12px', verticalAlign:'middle'}}>Voucher Code: </label>
                  {isVoucherApplied ? <><p name="vouchercode" id="vouchercode" className="vouchercodeMobile" style={{display: 'inline-block'}}>{this.state.vouchercode}</p></>:<><input name="vouchercode" placeholder="Enter a Voucher Code" id="vouchercode" className="vouchercodeMobile" onChange={(e)=>this.handleVoucherCode(e)}/></> }
                </div>
                <div style={{display:'inline-block', width:'100%', textAlign:'end'}}>
                  <button
                    className="checkVoucherMobile"
                    style={isVoucherApplied ? removeVoucherStyle : null}
                    onClick={isVoucherApplied ? () => this.removeVoucher() : () => this.checkVoucher()}
                      >
                    {btnvoucherdisplay}
                  </button>
                </div>
              </div>
              <div className="text" style={{ fontSize: '12px', display: 'inline-block', width: '50%', margin: 0 }}>
                {
                  isVoucherApplied ? 
                  <>
                    <h3 style={{margin:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="dineinTotal" style={{color:'#1F5626'}}></span></h3>
                    <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                    <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500'}}>eVoucher: - ${parseInt(this.state.voucheramt).toFixed(2)}</p>
                  </>
                  :
                  <>
                    <h3 style={{marginBottom:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="dineinTotal" style={{color:'#1F5626'}}>${price.toFixed(2)}</span></h3>
                    <p className="text" style={{fontSize:'12px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                  </>
                }
              </div>
                <div style={{display:'inline-block', width:'50%', textAlign:'end'}}>
                  <button
                    className="buttonCheckoutMobile"
                    style={isButtonDisabled ? disabledStyle : null}
                    disabled={isButtonDisabled}
                    onClick={()=>this.checkout()}
                    >
                    {btndisplay}
                  </button>
                </div>
              <Link to={linkurl} id="checkout" style={{display:'none', width:'50%'}}>
                <button  style={{display:'none', width:'50%', fontSize:'12px'}}>{btndisplay}</button>
              </Link>
            </>
              
            }
            </div>
          </div>
          </div> : <></>}
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Checkout " + this.state.functionIndex,
          }}/>:<></>}
      </div>
      );
    } else {
      return (
        <div style={{ marginBottom: '260px'}}>
        <div className="drawerWeb" style={{ height: 'auto', padding:'30px'}}>
          <Link to={{pathname:`/products`}}>
            <button className="backWeb" >Back</button>
          </Link>
        </div>
      <div style={bgstyle}>
          <h1 className="center" style={{fontSize:'28px', marginTop:0}}>CHECK OUT</h1>
         
          {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <h4 className="stall" style={{fontSize:'22px'}}>{this.state.merchantname}: {this.state.merchantunit}</h4> : <></>}
          {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <><Order onUpdate={this.updateParentValue} data={this.state.orders} displaydata={this.state.ordersdisplay}/></>:
          <div style={{textAlign:'center',marginTop:'5%'}}>
            <img src={emptycart} width={250} style={{borderRadius:'10px'}}/>
            <p className="text" style={{fontSize:'24px', color:'#6D7278'}}>Oops...</p>
            <p className="text" style={{fontSize:'24px', color:'#6D7278',marginBottom:'50px'}}>Your Cart is Empty.</p>
                { isCluster == 2 ? <Link to='/web/clusters' style={{textDecoration: 'none'}}>
              <button className="button5Web" style={{width:'30%',marginTop:'30px', fontSize:'18px', height:'50px'}}>BROWSE STALLS</button>
                </Link> : 
            <Link to='/merchants' style={{textDecoration: 'none'}}>
            <button className="button5Web" style={{width:'30%',marginTop:'30px', fontSize:'18px', height:'50px'}}>BROWSE STALLS</button>
          </Link>}
            
          </div>}
          
      </div>
      {JSON.parse(localStorage.getItem('orders')) && JSON.parse(localStorage.getItem('orders')).length > 0 ? <div className="checkoutCartWeb">  
          <div className="paymentCartWeb">
              {this.state.dinemode == 3 ? <>
                  <div className="voucherContainer">
                    <div style={{display:'inline-block', width:'50%'}}>
                    <label className="text" htmlFor="vouchercode" style={{ fontWeight: 'bold', fontSize: '20px', verticalAlign: 'middle' }}>Voucher Code: </label>
                    {isVoucherApplied ? <><p name="vouchercode" id="vouchercode" className="vouchercode" style={{display: 'inline-block'}}>{this.state.vouchercode}</p></>:<><input name="vouchercode" placeholder="Enter a Voucher Code" id="vouchercode" className="vouchercode" onChange={(e)=>this.handleVoucherCode(e)}/></> }
                      
                    </div>
                    <div style={{display:'inline-block', width:'50%', textAlign:'end'}}>
                      <button
                      className="checkVoucher"
                      style={isVoucherApplied ? removeVoucherStyle : null}
                      onClick={isVoucherApplied ? () => this.removeVoucher() : () => this.checkVoucher()}
                        >
                      {btnvoucherdisplay}
                      </button>
                    </div>
                  </div>
                  {isVoucherApplied ?
                    <div className="text" style={{ fontSize: '18px', display: 'inline-block', width: '50%', margin: 0 }}>
                      <h3 style={{margin:0, width:'fit-content'}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="takeawayTotal" style={{color:'#1F5626'}}></span></h3>
                      <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500', width:'fit-content'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                      <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500', width:'fit-content'}}>eVoucher: - ${parseInt(this.state.voucheramt).toFixed(2)}</p>
                      <p className="text" style={{ fontSize: '20px', margin: 0, color: '#6D7278', fontWeight: '500', width: 'fit-content' }}>Takeaway Charge: + ${additional.toFixed(2)}</p>
                    </div>
                    : <div className="text" style={{ fontSize: '18px', display: 'inline-block', width: '50%', margin: 0 }}>
                         <h3 style={{margin:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="takeawayTotal" style={{color:'#1F5626'}}>${(price + additional).toFixed(2)}</span></h3>
                        <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                        <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500'}}>Takeaway Charge: + ${additional.toFixed(2)}</p>
                    </div>
                  }
                  
                  <div style={{display:'inline-block', width:'50%', textAlign:'end', verticalAlign: 'bottom'}}>
                    <button
                      className="buttonCheckout"
                      style={isButtonDisabled ? disabledStyle : null}
                      disabled={isButtonDisabled}
                      onClick={()=>this.checkout()}
                      >
                      {btndisplay}
                    </button>
                  </div>
                  <Link to={linkurl} id="checkout" style={{display:'none', width:'50%'}}>
                    <button  style={{display:'none', width:'50%'}}>{btndisplay}</button>
                  </Link>
                </>:
                <>
                   <div className="voucherContainer">
                    <div style={{display:'inline-block', width:'50%'}}>
                      <label className="text" htmlFor="vouchercode" style={{fontWeight:'bold', fontSize:'20px', verticalAlign:'middle'}}>Voucher Code: </label>
                      {isVoucherApplied ? <><p name="vouchercode" id="vouchercode" className="vouchercode" style={{display: 'inline-block'}}>{this.state.vouchercode}</p></>:<><input name="vouchercode" placeholder="Enter a Voucher Code" id="vouchercode" className="vouchercode" onChange={(e)=>this.handleVoucherCode(e)}/></> }
                    </div>
                    <div style={{display:'inline-block', width:'50%', textAlign:'end'}}>
                      <button
                        className="checkVoucher"
                        style={isVoucherApplied ? removeVoucherStyle : null}
                        onClick={isVoucherApplied ? () => this.removeVoucher() : () => this.checkVoucher()}
                          >
                        {btnvoucherdisplay}
                      </button>
                    </div>
                  </div>
                  <div className="text" style={{ fontSize: '20px', display: 'inline-block', width: '50%', margin: 0 }}>
                    {
                      isVoucherApplied ? 
                      <>
                        <h3 style={{margin:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="dineinTotal" style={{color:'#1F5626'}}></span></h3>
                        <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                        <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500'}}>eVoucher: - ${parseInt(this.state.voucheramt).toFixed(2)}</p>
                      </>
                      :
                      <>
                        <h3 style={{marginBottom:0}}>Total <span style={{color:'#1F5626'}}>{qty}</span> item(s): <span id="dineinTotal" style={{color:'#1F5626'}}>${price.toFixed(2)}</span></h3>
                        <p className="text" style={{fontSize:'20px',margin:0,color:'#6D7278', fontWeight:'500'}}>Subtotal: + ${subtotal.toFixed(2)}</p>
                      </>
                    }
                  </div>
                  <div style={{display:'inline-block', width:'50%', textAlign:'end', verticalAlign: 'bottom'}}>
                    <button
                      className="buttonCheckout"
                      style={isButtonDisabled ? disabledStyle : null}
                      disabled={isButtonDisabled}
                      onClick={()=>this.checkout()}
                      >
                      {btndisplay}
                    </button>
                  </div>
                  <Link to={linkurl} id="checkout" style={{display:'none', width:'50%'}}>
                    <button  style={{display:'none', width:'50%'}}>{btndisplay}</button>
                  </Link>
                </>}
                
              </div>
          </div> : <></>}
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Checkout " + this.state.functionIndex,
          }}/>:<></>}
      </div>
      
      );
   }
  }
}
 
export default Checkout;