import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Shop from '../components/merchant_category_component'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckoutDetails from '../../checkout_module/components/checkout_details_component'
import loading from '../../../assets/payments/loading.gif'
import Button from '@mui/material/Button';
import '../../../styles/menu_styles.css'
import { Dialog } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import ErrorAlert from '../../common/components/alert_error_component'
import sparkle from '../../../../src/assets/background/sparkle.gif';

import axios from "axios";
import commonConfigs from "../../../config";


const badgeStyleUnit = {
  "& .MuiBadge-badge": {
    color: 'black',
    backgroundColor: 'white',
    width:'max-content',
  }, 
}

const isCluster = process.env.REACT_APP_IS_CLUSTER;
export default class stall_layout extends Component{
  constructor(props) {
    super(props);
    this.state = {
      allmerchantsOG: [],
      allmerchants: [],
      allcattags:[],
      load:true,
      open: false,
      sitemap: "",
      errorMsg:"",
      dinemode:localStorage.getItem('dinemode'),
      peftype: localStorage.getItem('peftype'),
      cluster: localStorage.getItem('clustername'),
      anonid: localStorage.getItem('anonid'),
      hawkerName: localStorage.getItem('hawkername'),
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
      merchantslist: [],
      merchantslistdisplay: [],
      inputValue: "",
      orgid: localStorage.getItem('orgid'),
      projectname: localStorage.getItem('projectname'),
      errorMsg: "",
      functionIndex:""
    };
    this.handleCatChange = this.handleCatChange.bind(this);
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;   
    
    // Check if we're at zero.
    if (secondstime == 0) { 
      clearInterval(this.timer);
      this.setState({
        timeout: true,
      });
    } else {
       this.setState({
      seconds: secondstime,
    });
    }
  }
 
  async getAllMerchants() {
    try {
      const orgid = localStorage.getItem('orgid');
      const projectname = localStorage.getItem('projectname');
      const loccode = localStorage.getItem('loccode');
      const merchanttype = localStorage.getItem('merchanttype');

      const dinemode = localStorage.getItem('dinemode');
      const collection_datetime = localStorage.getItem('collection_datetime')
      const platformSyscode = localStorage.getItem("platformSyscode")
      var clusterNameSesh = "";
      if (isCluster == 2) {
        clusterNameSesh = localStorage.getItem('clustername');
      } else {
        clusterNameSesh = process.env.REACT_APP_CLUSTER_NAME;
      }
      const emenu_merchants = localStorage.getItem('emenu_merchants');
      const emenu_cats = localStorage.getItem('emenu_cats');
      const emenu_products = localStorage.getItem('emenu_products');
      const emenu_product_cats = localStorage.getItem('emenu_product_cats');
      const emenu_hpbcats = localStorage.getItem('emenu_hpbcats');
      const sitemap = localStorage.getItem('sitemap');

      const method = "GET";
      const getStallsUrl = commonConfigs.apiUrls.GetStalls(orgid, loccode);
      const requestBody = {}; 

      // Generate authentication headers
      const authHeaders = commonConfigs.generateHeaders(method, getStallsUrl, requestBody);

      await axios
        .get(getStallsUrl, { headers: authHeaders })
        .then((res) => {
          if (res.data.status == "success") {
          var response = res.data.result.data;
          //compile all merchant tags
          var allCatTags = [];
          var allMerchants = [];
          var arrOfMerchantsDisplay;
          var arrOfMerchants = [];
          var availableMerchants = [];

          for (var i = 0; i < response.length; i++) {
            if (response[i].merchant_preference.is_merchant_visible !== 0) {
              allCatTags.push(response[i].merchant_tags);
            }
            const resultPEF = response[i].merchant_tags.find(({ tag_category }) => tag_category === "PayEatFwd");
            const resultPEF2 = response[i].merchant_tags.find(({ tag_category }) => tag_category === "PAYEATFWD");
            
            if ((resultPEF || resultPEF2)) {
              arrOfMerchants.push(response[i]);
            }
            
            if (response[i].merchant_preference.is_merchant_visible !== 0) {
              allMerchants.push(response[i]);
            }

            if (dinemode == '99') {
              arrOfMerchantsDisplay = arrOfMerchants;
            } else {
              arrOfMerchantsDisplay = allMerchants;
            }
            console.log(arrOfMerchantsDisplay);
                  
          }
              
          //concat arrays
          var concatCatTags = [];
          for (var a = 0; a < allCatTags.length; a++) {
            concatCatTags = concatCatTags.concat(allCatTags[a]);
          }
          //filter and remove duplicate
          var finalCatTags = [];

          const filteredCatTags = concatCatTags.filter(element => {
            const isDuplicate = finalCatTags.includes(element.tag_category_id);
              
            if (!isDuplicate) {
              finalCatTags.push(element.tag_category_id);
              return true;
            }
            
            return false;
          });
          localStorage.setItem('emenu_merchants', JSON.stringify(allMerchants));

          var newFiltered = [{
            tag_category_id: 0,
            tag_category_img_url: "https://www.cspinet.org/sites/default/files/styles/700x530/public/2021-11/december21_bn_hero_700x530.jpg?h=61bc1599",
            tag_category: "All Merchants"
          }];
              
          for (var i = 0; i < filteredCatTags.length; i++) {
            if (filteredCatTags[i].tag_category !== "PayEatFwd" && !filteredCatTags[i].tag_category.includes('Cluster') && !filteredCatTags[i].tag_category.includes('CLUSTER') && filteredCatTags[i].tag_category !== "Kiosk" && filteredCatTags[i].tag_category !== "KIOSK") {
              newFiltered.push(filteredCatTags[i]);
            }
          }
          localStorage.setItem('emenu_cats', JSON.stringify(newFiltered));
        
          this.setState({
            allmerchantsOG: arrOfMerchantsDisplay,
            allmerchants: arrOfMerchantsDisplay,
            merchantslistdisplay: arrOfMerchantsDisplay,
            allcattags: newFiltered,
            errorMsg: "",
            load: false
          });

          } else {
            this.setState({
              errorImg: sparkle,
              errorMsg: "Retrieving eMenu.  Please wait a moment...",
              load: false
            });
        }

        }).catch((err) => {
          console.log(err);
          if (err.code == "ERR_BAD_REQUEST") { 
            this.setState({
              errorImg: sparkle,
              errorMsg: "No stalls available at the moment. Please try again later.",
              load: false
            });
          } else {
              this.setState({
                errorImg: sparkle,
                errorMsg: err.message,
                load: false
              });
            }
          })

    
      if (dinemode == 99) {
        const peftype = localStorage.getItem('peftype');
        
        localStorage.clear();
        localStorage.setItem('dinemode', dinemode);

        localStorage.setItem('orgid', orgid);
        localStorage.setItem('projectname', projectname);
        localStorage.setItem('loccode', loccode);

        localStorage.setItem('clustername', clusterNameSesh);
        localStorage.setItem('peftype', peftype);
        localStorage.setItem('locationcode', loccode);
        localStorage.setItem('anonid', this.state.anonid)
        localStorage.setItem('platformSyscode', platformSyscode)

        if (emenu_merchants !== null) {
          localStorage.setItem('emenu_merchants', emenu_merchants)
        }
        if (emenu_cats !== null) {
          localStorage.setItem('emenu_cats', emenu_cats)
        }
        if (emenu_product_cats !== null) {
          localStorage.setItem('emenu_product_cats', emenu_product_cats)
        }
        if (emenu_hpbcats !== null) {
          localStorage.setItem('emenu_hpbcats', emenu_hpbcats)
        }
        if (sitemap !== null) {
          localStorage.setItem('sitemap', sitemap)
        }
        
      } else {
        localStorage.clear();
        localStorage.setItem('dinemode', dinemode);
        localStorage.setItem('orgid', orgid);
        localStorage.setItem('projectname', projectname);
        localStorage.setItem('loccode', loccode);

        localStorage.setItem('clustername', clusterNameSesh);
        localStorage.setItem('locationcode', loccode);
        localStorage.setItem('collection_datetime', collection_datetime);
        localStorage.setItem('merchanttype', merchanttype);
        localStorage.setItem('platformSyscode', platformSyscode)
        
        if (emenu_merchants !== null) {
          localStorage.setItem('emenu_merchants', emenu_merchants)
        }
        if (emenu_cats !== null) {
          localStorage.setItem('emenu_cats', emenu_cats)
        }
        if (emenu_product_cats !== null) {
          localStorage.setItem('emenu_product_cats', emenu_product_cats)
        }
        if (emenu_hpbcats !== null) {
          localStorage.setItem('emenu_hpbcats', emenu_hpbcats)
        }
        if (sitemap !== null) {
          localStorage.setItem('sitemap', sitemap)
        }

      }
    }catch(e){
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "01"
      }); 
    }
    
  }
  
  handleCatChange(tagName){
    this.setState({
      load:true
    });
    var filteredMerchants = [];
    
    if (tagName == 'All Merchants') {
      this.setState({
        allmerchants: this.state.allmerchantsOG,
        merchantslistdisplay: this.state.allmerchantsOG,
        load:false
      });  
    } else {
      for (var i = 0; i < this.state.allmerchantsOG.length; i++){
        const resultFilter = this.state.allmerchantsOG[i].merchant_tags.find(({ tag_category }) => tag_category === tagName);

        if (resultFilter) {
          filteredMerchants.push(this.state.allmerchantsOG[i]);  
        }
      }
      console.log(filteredMerchants);
      localStorage.setItem('emenu_merchants2', JSON.stringify(filteredMerchants));
      this.setState({
        allmerchants: filteredMerchants,
        merchantslistdisplay: filteredMerchants,
        load:false
      });
    }
  };

  handleClickOpen = () =>{
    this.setState({
      open: true
    });
  };

  handleClose = () =>{
    this.setState({
      open: false
    });
  };
  toMerchant(id,name, unit,charge){
    localStorage.setItem("merchantid",id);
    localStorage.setItem("merchantname",name);
    localStorage.setItem("merchantunit",unit)
    localStorage.setItem('takeawaycharge',charge);
  }

  onChangeSearch(e) {
    console.log(e.target.value)
    var results = this.state.allmerchants.filter(menu =>
      menu.merchant_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      inputValue: e.target.value,
      merchantslistdisplay:results
    })
  }

  componentDidMount() {
    this.getAllMerchants();
  }

  render() {
    clearInterval();
    console.log(this.state.merchantslistdisplay)
    
    if (window.innerWidth < 481) {
      var linkurl = `/options/${this.state.hawkerName}`
      return (
        <>
          <div style={{height:'100vh'}}>
            <Box sx={{ display: 'block' }}>
              <CssBaseline />
              <div style={{display:'inline-flex', height:'140px' }}>
                <div className="drawerWeb" style={{paddingTop:'30px'}}>
                  <Link to={{pathname:`${linkurl}`}}>
                    <button className="backMobile">Back</button>
                  </Link>
                </div>
                  <Divider orientation="vertical" flexItem/>
                  <List style={{ display:'inline-flex', overflow: 'auto',whiteSpace: 'nowrap'}}>
                    {this.state.allcattags && this.state.allcattags.map((cat, index) => (
                      <ListItem key={index} disablePadding onClick={() => this.handleCatChange(cat.tag_category)} style={{display: 'inline-block', width:'fit-content'}}>
                        <ListItemButton>
                          <Shop data={cat}/>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    
                  </List>
              </div>
              <Divider/>
              <div style={{ margin: '20px' }}>
                <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
              </div>
            <h2 style={{textAlign:'center'}}>Merchants</h2>
  
              {this.state.load == false && this.state.errorMsg == "" ?                
                  <Box
                      component="main"
                      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                      style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridGap: 10, paddingTop:0}}
                    >
                  {this.state.merchantslistdisplay && this.state.merchantslistdisplay.map((merch, index) => (
                    <div key={index} style={{ width: "100%", marginBottom: "0px", position:'relative'}}>
                          {(merch.meals_donated == 0 && this.state.peftype === "Claim") || (merch.meals_donated == merch.meals_claimed && this.state.peftype === "Claim") ? 
                          <div key={index} style={{marginTop: "3%", width: "100%", marginBottom: "0px", position:'relative', height:'30%', opacity:'0.5', margin:'auto'}}>
                              <Card sx={{ maxWidth: 300 }} style={{marginTop: "20px", position:'relative', height:'70%', borderRadius:'10px',margin:'auto'}} onClick={()=>this.toMerchant(merch.merchant_id,merch.merchant_name, merch.merchant_unit_no,merch.merchant_preference.takeaway_charge)} >
                              <Badge style={{position: "absolute",left: "40px",top: "20px"}} sx={badgeStyleUnit} max={1000} badgeContent={"#"+merch.merchant_unit_no} />
                              {merch.merchant_img_url !== null && merch.merchant_img_url !== "" ? <CardMedia
                                component="img"
                                height="250"
                                image={merch.merchant_img_url}
                                alt="burger"
                              /> : <CardMedia
                              component="img"
                              height="250"
                              image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
                              alt="burger"
                            />}
                            
                              </Card>
                            {this.state.dinemode == 99 ? 
                            <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                              <div style={{width: "180px"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                              <div>
                              <Typography gutterBottom variant="h6" component="span" style={{color:'#1F2937',fontWeight:'bold',fontSize:'22px', textAlign:'end'}}>
                                {merch.meals_donated} donated meals
                              </Typography>
                              <Typography variant="body2" color="text.secondary" style={{textAlign:'end',fontSize:'22px'}}>
                                {merch.meals_claimed} claimed
                              </Typography>
                              </div> 
                              
                            </CardContent> 
                            :
                            <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                              <div style={{width: "100%"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                            </CardContent> }
                        </div> :
                        <div key={index} style={{marginTop: "3%", width: "100%", marginBottom: "0px", position:'relative', margin:'auto'}}>
                        <Link to={`/products`} style={{textDecoration:'none'}}> 
                            <Card sx={{ maxWidth: 370 }} style={{marginTop: "25px", position:'relative', height:'70%', borderRadius:'10px',margin:'auto'}} onClick={()=>this.toMerchant(merch.merchant_id,merch.merchant_name, merch.merchant_unit_no,merch.merchant_preference.takeaway_charge)} >
                            <Badge style={{position: "absolute",left: "40px",top: "20px"}} sx={badgeStyleUnit} max={1000} badgeContent={merch.merchant_unit_no} />
                            {merch.merchant_img_url !== null && merch.merchant_img_url !== "" ? <CardMedia
                              component="img"
                              height="250"
                              image={merch.merchant_img_url}
                              alt="burger"
                            /> : <CardMedia
                            component="img"
                            height="250"
                            image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
                            alt="burger"
                          />}
                            
                            
                            </Card>
                            {this.state.dinemode == 99 ? 
                            <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                              <div style={{width: "180px"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                              <div>
                              <Typography gutterBottom variant="h6" component="span" style={{color:'#1F2937',fontWeight:'bold',fontSize:'22px', textAlign:'end'}}>
                                {merch.meals_donated} donated meals
                              </Typography>
                              <Typography variant="body2" color="text.secondary" style={{textAlign:'end',fontSize:'22px'}}>
                                {merch.meals_claimed} claimed
                              </Typography>
                              </div> 
                              
                            </CardContent> 
                            :
                            <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                              <div style={{width: "100%"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                              
                            </CardContent> }
                          </Link>
                            </div>
                          }
                        </div>  
                    
                ))}
                    <Dialog
                      onClose={() =>this.handleClose()}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.open}
                      maxWidth="md"
                    >
                      <DialogTitle id="customized-dialog-title" onClose={() =>this.handleClose()}>
                        <h1>Site Map</h1>
                      </DialogTitle>
                      <DialogContent dividers>
                          <img alt="SiteMap" src={this.state.sitemap} width="100%"/>                        
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() =>this.handleClose()} variant="contained" color="success" sx={{width: 880}} style={{backgroundColor:"#5BBA44", borderRadius:'10px', fontSize:'28px', height:'85px', marginTop:'20px'}}>CLOSE</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                // </div>
                : 
                <>
                {this.state.load == false && this.state.errorMsg !== "" ? <div style={{width:'100%', textAlign:'center'}}>
                 <img height="inherit" width="10%" src={this.state.errorImg} />
                 <h2 className="text" style={{marginLeft: '10%', width:'80%',fontSize:'18px', color:'#6D7278',fontWeight:'bold'}}>Oops...{ this.state.errorMsg}</h2>
               </div> : <img height="200px" width="200px" style={{ display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}src={loading}/>}
              </>
              }    
            
            </Box>
            <CheckoutDetails/>
          </div>
          {
          this.state.errorMsg == "System Error Code"  ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Merchant Layout " + this.state.functionIndex,
          }}>
          </ErrorAlert> : <></>
          }
        </>
      );
    } else {
      return (
        <>
          <div style={{height:'150vh'}}>
            <Box sx={{ display: 'block' }}>
              <CssBaseline />
              <div style={{display:'inline-flex'}}>
                <div className="drawerWeb">
                  <Link to={{pathname:`/locations`}}>
                    <button className="backWeb" >Back</button>
                  </Link>
                </div>
                  <Divider orientation="vertical" flexItem/>
                  <List style={{ maxHeight: '185px',display:'inline-flex'}}>
                    {this.state.allcattags && this.state.allcattags.map((cat, index) => (
                      <ListItem key={index} disablePadding onClick={() => this.handleCatChange(cat.tag_category)}>
                        <ListItemButton>
                          <Shop data={cat}/>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
              </div>
              <Divider/>
              <div style={{ margin: '20px 200px 0 200px' }}>
                <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
              </div>
               <h1 style={{textAlign:'center'}}>Merchants</h1>
              {this.state.load == false && this.state.errorMsg == "" ?                
                <Box
                  component="main"
                  sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                  style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 10 }}
                >
                  {this.state.merchantslistdisplay && this.state.merchantslistdisplay.map((merch, index) => (                  
                    <div key={index} style={{ width: "100%", marginBottom: "0px", position:'relative'}}>
                          {(merch.meals_donated == 0 && this.state.peftype === "Claim") || (merch.meals_donated == merch.meals_claimed && this.state.peftype === "Claim") ? 
                          <div key={index} style={{marginTop: "3%", width: "100%", marginBottom: "0px", position:'relative', height:'30%', opacity:'0.5', margin:'auto'}}>
                              <Card sx={{ maxWidth: 300 }} style={{marginTop: "20px", position:'relative', height:'70%', borderRadius:'10px',margin:'auto'}} onClick={()=>this.toMerchant(merch.merchant_id,merch.merchant_name, merch.merchant_unit_no,merch.merchant_preference.takeaway_charge)} >
                              <Badge style={{position: "absolute",left: "40px",top: "20px"}} sx={badgeStyleUnit} max={1000} badgeContent={"#"+merch.merchant_unit_no} />
                              {merch.merchant_img_url !== null && merch.merchant_img_url !== "" ? <CardMedia
                                component="img"
                                height="250"
                                image={merch.merchant_img_url}
                                alt="burger"
                              /> : <CardMedia
                              component="img"
                              height="250"
                              image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
                              alt="burger"
                            />}
                              
                              
                              </Card>
                              {this.state.dinemode == 99 ? 
                              <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                                <div style={{width: "180px"}}>
                                <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                  {merch.merchant_name}
                                </Typography>
                                </div>
                                <div>
                                <Typography gutterBottom variant="h6" component="span" style={{color:'#1F2937',fontWeight:'bold',fontSize:'22px', textAlign:'end'}}>
                                  {merch.meals_donated} donated meals
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{textAlign:'end',fontSize:'22px'}}>
                                  {merch.meals_claimed} claimed
                                </Typography>
                                </div> 
                                
                              </CardContent> 
                              :
                              <CardContent style={{display: "inline-flex", paddingBottom:0, marginLeft:'30px'}}>
                                <div style={{width: "100%"}}>
                                <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                  {merch.merchant_name}
                                </Typography>
                                </div>
                              </CardContent> }
                        </div> :
                        <div key={index} style={{marginTop: "3%", width: "100%", marginBottom: "0px", position:'relative', margin:'auto'}}>
                        <Link to={`/products`} style={{textDecoration:'none'}}> 
                            <Card sx={{ maxWidth: 370 }} style={{marginTop: "25px", position:'relative', height:'70%', borderRadius:'10px',margin:'auto'}} onClick={()=>this.toMerchant(merch.merchant_id,merch.merchant_name, merch.merchant_unit_no,merch.merchant_preference.takeaway_charge)} >
                            <Badge style={{position: "absolute",left: "40px",top: "20px"}} sx={badgeStyleUnit} max={1000} badgeContent={merch.merchant_unit_no} />
                            {merch.merchant_img_url !== null && merch.merchant_img_url !== "" ? <CardMedia
                              component="img"
                              height="250"
                              image={merch.merchant_img_url}
                              alt="burger"
                            /> : <CardMedia
                            component="img"
                            height="250"
                            image="https://www.seriouseats.com/thmb/Lst6N6tBU2_krN-2eyME34V0_mU=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2021__02__20210204-funghi-pasta-vicky-wasik-17-55074a9a66864d14afecf8cbd4445597.jpg"
                            alt="burger"
                          />}
                          
                            </Card>
                            {this.state.dinemode == 99 ? 
                            <CardContent style={{display: "inline-flex", paddingBottom:0}}>
                              <div style={{width: "180px"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                              <div>
                              <Typography gutterBottom variant="h6" component="span" style={{color:'#1F2937',fontWeight:'bold',fontSize:'22px', textAlign:'end'}}>
                                {merch.meals_donated} donated meals
                              </Typography>
                              <Typography variant="body2" color="text.secondary" style={{textAlign:'end',fontSize:'22px'}}>
                                {merch.meals_claimed} claimed
                              </Typography>
                              </div> 
                              
                            </CardContent> 
                            :
                            <CardContent style={{display: "inline-flex", paddingBottom:0, marginLeft:'30px'}}>
                              <div style={{width: "100%"}}>
                              <Typography gutterBottom variant="h5" component="span"style={{fontWeight:700, color:'#6D7278',fontSize:'18px'}} >
                                {merch.merchant_name}
                              </Typography>
                              </div>
                            </CardContent> }
                          </Link>
                            </div>
                          }
                        </div>  
                ))}
                    <Dialog
                      onClose={() =>this.handleClose()}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.open}
                      maxWidth="md"
                    >
                      <DialogTitle id="customized-dialog-title" onClose={() =>this.handleClose()}>
                        <h1>Site Map</h1>
                      </DialogTitle>
                      <DialogContent dividers>
                          <img alt="SiteMap" src={this.state.sitemap} width="100%"/>                        
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() =>this.handleClose()} variant="contained" color="success" sx={{width: 880}} style={{backgroundColor:"#5BBA44", borderRadius:'10px', fontSize:'28px', height:'85px', marginTop:'20px'}}>CLOSE</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                : 
                <>
                {this.state.load == false && this.state.errorMsg !== "" ? <div style={{width:'100%', textAlign:'center'}}>
                 <img height="inherit" width="10%" src={this.state.errorImg} />
                 <h2 className="text" style={{marginLeft: '10%', width:'80%',fontSize:'18px', color:'#6D7278',fontWeight:'bold'}}>Oops...{ this.state.errorMsg}</h2>
               </div> : <img height="fit-content" style={{margin:'auto', padding:'30px', display:'block'}}src={loading}/>}
              </>
              }    
            
            </Box>
            <CheckoutDetails/>
          </div>
          {
            this.state.errorMsg == "System Error Code"  ? <ErrorAlert errorMsgDisplay={{
              title: this.state.errorMsg,
              body: "Merchant Layout " + this.state.functionIndex,
            }}>
            </ErrorAlert> : <></>
          }
        </>
        
      );
    }
  }
  
}