import React, { Component } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { initializeApp } from "firebase/app";
// import firebaseConfig from "../../../firebase_config";
import "../../../styles/menu_styles.css";

import commonConfigs from "../../../config";
import loadingIcon from '../../../assets/payments/loading.gif'

const isCluster = process.env.REACT_APP_IS_CLUSTER;

export default class PastOrdersLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      data: [],
      columns: [],
      display: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleMobile = (e) => {
    this.setState({
      mobile: e.target.value,
    });
  };

  getRowId(row) {
    return row.consumer_order_id;
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.state.mobile === "") {
      alert("Please enter a mobile number.");
      return;
    }

    this.setState({ loading: true });

    const columns = [
      { headerName: "Order #", field: "order_no", width: 90 },
      { headerName: "Payment ID", field: "payment_id", width: 150 },
      { headerName: "Order Datetime", field: "order_datetime", width: 200 },
      { headerName: "Platform", field: "platform_type", width: 100 },
      { headerName: "Payment Type", field: "payment_type", width: 120 },
      { headerName: "Merchant", field: "merchant_name", width: 150 },
      { headerName: "Order Status", field: "order_status", width: 150 },
      { headerName: "Mode", field: "order_type", width: 200 },
    ];

    const getTxnHistoryUrl = commonConfigs.apiUrls.GetTxnHistory(
      this.state.mobile,
      0,
      "foodcanopy"
    );
    console.log(getTxnHistoryUrl);

    const method = "GET";
    const endpoint = getTxnHistoryUrl;
    const requestBody = {};

    const authHeaders = commonConfigs.generateHeaders(
      method,
      endpoint,
      requestBody
    );

    try {
      const res = await axios.get(getTxnHistoryUrl, { headers: authHeaders });
      const response = res.data.result;
      this.setState({
        data: response.data,
        columns: columns,
        display: true,
        loading: false,
      });
    } catch (err) {
      console.error("Error Message: ", err);
      this.setState({ loading: false });
    }
  }

  // componentDidMount() {
  //   initializeApp(firebaseConfig);
  // }

  render() {
    let linkurl;
    if (isCluster == 0 || isCluster == 1) {
      linkurl = "/merchants";
    } else {
      linkurl = "/web/clusters";
    }

    return (
      <div style={{ position: "relative", width: "100%", height: "100vh" }}>
        <Link
          to={{ pathname: `/` }}
          style={{ position: "absolute", top: "10px", left: "5%" }}
        >
          {window.innerWidth < 481 ? (
            <button
              className="backWeb"
              style={{ fontSize: "12px", height: "inherit", width: "auto" }}
            >
              Back
            </button>
          ) : (
            <button className="backWeb">Back</button>
          )}
        </Link>

        <h2
          className="textdiningmode"
          style={{ fontSize: "24px", top: "15%" }}
        >
          Enter Mobile Number
        </h2>

        <div className="container" style={{ padding: 0, top: "22%" }}>
          <div className="clearfix" style={{ textAlign: "left" }}>
            <form
              onSubmit={this.handleSubmit}
              style={{ width: "35%", margin: "auto" }}
            >
              <div style={{ display: "inline-flex" }}>
                <div className="col-span-6 sm:col-span-3 mt-5">
                  <Typography
                    id="mobile"
                    variant="h6"
                    component="h2"
                    style={{
                      fontFamily: "Arial, Helvetica, sans-serif",
                      fontSize: "18px",
                      fontWeight: "bold",
                      letterSpacing: 0.16,
                      marginTop: "30px",
                    }}
                  >
                    Mobile
                  </Typography>
                  <TextField
                    id="mobile"
                    label="Enter Mobile Number"
                    variant="standard"
                    onChange={this.handleMobile}
                    value={this.state.mobile}
                  />
                </div>
              </div>

              <div style={{ textAlign: "center", margin: "20px" }}>
                <button
                  className="continueWeb"
                  type="submit"
                  style={{ width: "200px" }}
                >
                  Retrieve
                </button>
              </div>
            </form>
          </div>

          {this.state.display && (
            <div className="clearfix" style={{ textAlign: "center" }}>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontWeight: "bold",
                  letterSpacing: "0.16",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Past Records for Mobile {this.state.mobile}
              </h2>
              <DataGrid
                getRowId={(e) => this.getRowId(e)}
                rows={this.state.data}
                columns={this.state.columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                style={{ width: "70%", margin: "auto" }}
              />
            </div>
          )}

          {this.state.loading && (
            <img
              height="200px"
              width="200px"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
              }}
              src={loadingIcon}
              alt="Loading"
            />
          )}
        </div>
      </div>
    );
  }
}
